import "./styles/footer.css";
function Footer() {
    return <footer className="footer">
    <p>Author: Carlos Tranquilino Carlos Roman </p>
    <p>Copyright © { new Date().getFullYear()} </p>
    <p><a href="/"> Home page </a></p>
  </footer> 
}

export default Footer;
